import React from 'react'
import { graphql } from 'gatsby'
import { Link } from "gatsby"
import styled, { css } from 'styled-components'

import Settings from 'src/styles/settings'
import { Media } from 'src/styles/mixins'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'
import Featured from 'src/components/molecules/Featured'
import Content from 'src/components/molecules/Content'
import Ads from 'src/components/atoms/Ads'
import Archive from 'src/components/molecules/Archive'
import Wrapper from 'src/components/organisms/Wrapper'
import Author from 'src/components/molecules/Author'
import Shared from 'src/components/molecules/Shared'

const Info = styled.div`
  max-width: 728px;
  margin: 0 auto 2rem;
  padding: 0 1rem;
  display: flex;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Item = styled.li`
  display: inline-block;
  margin-right: 1rem;
`;

const ItemLink = styled(Link)`
  color: ${Settings.colors.keyColor};
  text-decoration: none;

  &::before {
    content: "#";
  }
`;

const Date = styled.div`
  margin-left: auto;
`;

const Post = ({ data }) => {
  const {
    title,
    slug,
    excerpt,
    content,
    image,
    tags,
    author,
    date,
    createdAt,
  } = data.contentfulPosts
  const settings = data.contentfulSettings
  const url = `https://${ settings.domain }/${ slug }`

  const tagPosts = tags.map(( tag ) => ( tag.posts ))
  const allTagPosts = tagPosts.reduce((prev, current) => [...prev, ...current])
  const recommendPosts = allTagPosts.filter((x,i,s) => s.indexOf(x) === i && i !== s.lastIndexOf(x))

  return (
    <Layout>
      <SEO
        title={ title }
        description={ excerpt.childMarkdownRemark.excerpt.replace(/\r?\n/g, '') }
        keywords={`${ tags && tags.map(( tag ) => ( tag.title ))}`}
        url={ url }
        ogpImage={ image.file.url } />

      <Featured
        title={ title }
        excerpt={ excerpt.childMarkdownRemark.html }
        url={ url }
        tags= { tags }
        image={ image.fluid }
        twitter={ author.twitter }
        settings={ settings } />

      <Ads type="article" />

      <Content body={ content.childMarkdownRemark.html } />

      <Shared title={ title } url={ url } twitter={ author.twitter } settings={ settings } />

      <Info>
        <List>
          { tags &&
            tags.map(( tag ) => (
              <Item><ItemLink to={`/tag/${tag.slug}`} dangerouslySetInnerHTML={{ __html: tag.title }} /></Item>
            ))
          }
        </List>
        <Date dangerouslySetInnerHTML={{ __html: date ? date : createdAt }} />
      </Info>

      <Author author={ author } />

      <Wrapper settings={ settings }>
        { console.log(allTagPosts) }
        <Archive type="main" title="おすすめの記事" titleEn="Recommended Articles" posts={ recommendPosts } />
      </Wrapper>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query($slug: String!) {
    contentfulPosts(slug: { eq: $slug }) {
      id
      date(formatString: "YYYY.MM.DD")
      createdAt(formatString: "YYYY.MM.DD")
      updatedAt(formatString: "YYYY.MM.DD")
      title
      slug
      excerpt {
        childMarkdownRemark {
          html
          excerpt
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        file {
          url
        }
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      tags {
        id
        slug
        title
        group
        posts {
          id
          date(formatString: "YYYY.MM.DD")
          createdAt(formatString: "YYYY.MM.DD")
          updatedAt(formatString: "YYYY.MM.DD")
          title
          slug
          excerpt {
            childMarkdownRemark {
              excerpt
              html
            }
          }
          image {
            fluid(maxWidth: 2000) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      author {
        id
        slug
        name
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid
          }
        }
        twitter
        facebook
        instagram
        line
      }
    }

    contentfulSettings(node_locale: {eq: "ja-JP"}) {
      siteName
      siteCaption
      siteDescription
      domain
      twitter
      facebook
      instagram
      line
      hashTag
    }
  }
`