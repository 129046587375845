import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import styled, { css } from 'styled-components'

import Avatar from "src/components/atoms/Avatar"
import Account from "src/components/molecules/Account"

import Settings from 'src/styles/settings'

import { Media, Neumorphism } from 'src/styles/mixins'

const Container = styled.aside`
  text-align: center;
  max-width: 728px;
  position: relative;
  border-radius: .5rem;
  ${Neumorphism};

  ${Media.desktop`
    margin: calc(60px + 2rem) auto 4rem;
    padding: calc(60px + 2rem) 2rem 3rem;
  `}

  ${Media.mobile`
    margin: calc(30px + 2rem) 2rem 4rem;
    padding: calc(30px + 2rem) 2rem 2rem;
  `}
`;

const Title = styled.h1`
  font-size: 1rem;
  margin: 0 auto 1rem;
`;

const Description = styled.div`
`;

const Author = ({ author }) => {
  const {
    image,
    slug,
    name,
    description,
    twitter,
    facebook,
    instagram,
    line,
  } = author

  return (
    <Container>
      { image && slug && name &&
        <Avatar card slug={ slug } name={ name } image={ image } />
      }

      { twitter && instagram && line && facebook &&
        <Account twitter={twitter} instagram={instagram} line={line} facebook={facebook} />
      }

      <Title dangerouslySetInnerHTML={{ __html: name }} />
      <Description dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }} />
    </Container>
  )
}

Author.propTypes = {
  author: PropTypes.object,
}

export default Author
